import React, { useState, useEffect, createContext, useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "./Buttons";
import { setAnalyticsCollectionEnabled } from "firebase/analytics";
import { analytics } from "../../firebase";
import CloseIcon from "@mui/icons-material/Close";
import posthog from 'posthog-js';

const BannerContainer = styled.div`
  ${tw`fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 p-4`}
`;

const BannerContent = styled.div`
  ${tw`max-w-screen-xl mx-auto relative`}
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700 transition-colors duration-200`}
`;

const Title = styled.h3`
  ${tw`text-lg font-bold mb-2`}
`;

const Text = styled.p`
  ${tw`text-sm text-gray-600 mb-4`}
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col sm:flex-row gap-2 justify-end`}
`;

const CookieSection = styled.div`
  ${tw`mb-4`}
`;

const CookieOption = styled.div`
  ${tw`flex items-center gap-2 mb-2`}
`;

const PrimaryButton = styled(PrimaryButtonBase)`
  ${tw`px-6 py-2`}
`;

const SecondaryButton = styled(PrimaryButtonBase)`
  ${tw`px-6 py-2`}
`;

const ExpandButton = styled.button`
  ${tw`text-xs text-primary-500 hover:text-primary-700 mt-1 focus:outline-none`}
`;

const CookieDetails = styled.div`
  ${tw`mt-2 text-xs text-gray-500 overflow-hidden transition-all duration-200`}
  max-height: ${(props) => (props.isExpanded ? "500px" : "0")};
  opacity: ${(props) => (props.isExpanded ? "1" : "0")};
`;

const CookieTable = styled.table`
  ${tw`w-full mt-2 text-sm`}
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  ${tw`border-b border-gray-200`}
  &:last-child {
    ${tw`border-b-0`}
  }
`;

const TableCell = styled.td`
  ${tw`py-2 pr-4`}
  &:first-child {
    ${tw`font-semibold w-1/4`}
  }
`;

const CookieConsentContext = createContext();

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error(
      "useCookieConsent must be used within a CookieConsentProvider",
    );
  }
  return context;
};

export const CookieConsentProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent) {
      const { analytics: hasAnalyticsConsent } = JSON.parse(consent);
      setAnalyticsEnabled(hasAnalyticsConsent);
      try {
        setAnalyticsCollectionEnabled(analytics, hasAnalyticsConsent);
        if (hasAnalyticsConsent) {
          posthog.opt_in_capturing();
        } else {
          posthog.opt_out_capturing();
        }
      } catch (error) {
        console.error("Analytics initialization error:", error);
      }
    } else {
      setIsOpen(true);
      try {
        setAnalyticsCollectionEnabled(analytics, false);
        posthog.opt_out_capturing();
      } catch (error) {
        console.error("Analytics initialization error:", error);
      }
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem(
      "cookieConsent",
      JSON.stringify({
        necessary: true,
        analytics: true,
        timestamp: new Date().toISOString(),
      }),
    );
    try {
      setAnalyticsCollectionEnabled(analytics, true);
      posthog.opt_in_capturing();
    } catch (error) {
      console.error("Analytics initialization error:", error);
    }
    setIsOpen(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem(
      "cookieConsent",
      JSON.stringify({
        necessary: true,
        analytics: analyticsEnabled,
        timestamp: new Date().toISOString(),
      }),
    );
    try {
      setAnalyticsCollectionEnabled(analytics, analyticsEnabled);
      if (analyticsEnabled) {
        posthog.opt_in_capturing();
      } else {
        posthog.opt_out_capturing();
      }
    } catch (error) {
      console.error("Analytics initialization error:", error);
    }
    setIsOpen(false);
  };

  const showBanner = () => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent) {
      const { analytics: hasAnalyticsConsent } = JSON.parse(consent);
      setAnalyticsEnabled(hasAnalyticsConsent);
    }
    setIsOpen(true);
    setShowDetails(true);
  };

  const handleClose = () => {
    const existingConsent = localStorage.getItem("cookieConsent");
    if (!existingConsent) {
      handleAcceptAll();
    } else {
      setIsOpen(false);
    }
  };

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <CookieConsentContext.Provider value={{ showBanner }}>
      {children}
      {isOpen && (
        <BannerContainer>
          <BannerContent>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            {!showDetails ? (
              <>
                <Title>We Value Your Privacy</Title>
                <Text>
                  We use necessary cookies for authentication and site
                  functionality. We'd also like to use analytics cookies to help
                  us improve our services. You can choose your preferences
                  below.
                </Text>
                <ButtonContainer>
                  <SecondaryButton onClick={() => setShowDetails(true)}>
                    Cookie Settings
                  </SecondaryButton>
                  <PrimaryButton onClick={handleAcceptAll}>
                    Accept All Cookies
                  </PrimaryButton>
                </ButtonContainer>
              </>
            ) : (
              <>
                <Title>Cookie Settings</Title>
                <CookieSection>
                  <CookieOption>
                    <input type="checkbox" checked={true} disabled />
                    <div>
                      <strong>Necessary Cookies</strong>
                      <Text>
                        These cookies are essential for authentication and basic
                        site functionality. They are required and cannot be
                        disabled.
                      </Text>
                      <ExpandButton onClick={() => toggleSection("necessary")}>
                        {expandedSection === "necessary"
                          ? "Show Less"
                          : "Show More"}
                      </ExpandButton>
                      <CookieDetails
                        isExpanded={expandedSection === "necessary"}
                      >
                        <CookieTable>
                          <tbody>
                            <TableRow>
                              <TableCell>Firebase Auth</TableCell>
                              <TableCell>
                                Stores your authentication state and session
                                information
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Cookie Consent</TableCell>
                              <TableCell>
                                Stores your cookie preferences (cookieConsent)
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Stripe</TableCell>
                              <TableCell>
                                Essential cookies for processing payments
                                securely. These cookies help with fraud
                                detection and secure checkout functionality.
                              </TableCell>
                            </TableRow>
                          </tbody>
                        </CookieTable>
                      </CookieDetails>
                    </div>
                  </CookieOption>
                  <CookieOption>
                    <input
                      type="checkbox"
                      checked={analyticsEnabled}
                      onChange={(e) => setAnalyticsEnabled(e.target.checked)}
                    />
                    <div>
                      <strong>Analytics Cookies</strong>
                      <Text>
                        These cookies collect anonymous information about how
                        you use our website. This helps us understand and
                        improve our services. No personal information is
                        collected.
                      </Text>
                      <ExpandButton onClick={() => toggleSection("analytics")}>
                        {expandedSection === "analytics"
                          ? "Show Less"
                          : "Show More"}
                      </ExpandButton>
                      <CookieDetails
                        isExpanded={expandedSection === "analytics"}
                      >
                        <CookieTable>
                          <tbody>
                            <TableRow>
                              <TableCell>Firebase Analytics</TableCell>
                              <TableCell>
                                Collects anonymous usage data to help us
                                understand how visitors interact with our
                                website. This includes information like page
                                views, time spent on site, and general
                                navigation patterns.
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>PostHog Analytics</TableCell>
                              <TableCell>
                                Helps us understand how users interact with our website by collecting
                                anonymous usage data. This includes page views, clicks, and user
                                journeys to improve our service and user experience.
                              </TableCell>
                            </TableRow>
                          </tbody>
                        </CookieTable>
                      </CookieDetails>
                    </div>
                  </CookieOption>
                </CookieSection>
                <ButtonContainer>
                  <SecondaryButton onClick={() => setShowDetails(false)}>
                    Back
                  </SecondaryButton>
                  <PrimaryButton onClick={handleSavePreferences}>
                    Save Preferences
                  </PrimaryButton>
                </ButtonContainer>
              </>
            )}
          </BannerContent>
        </BannerContainer>
      )}
    </CookieConsentContext.Provider>
  );
};

export const CookieBanner = CookieConsentProvider;
