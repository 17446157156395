import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useContext, useState, useEffect, createContext } from "react";

import CountryTranslator from "utils/CountryTranslator";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const fbFunctions = getFunctions(app, "europe-west1");

export { auth, db, analytics };
export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [pending, setPending] = useState();
  const [verifyTimestamp, setVerifyTimestamp] = useState();

  const verifyEmail = async () => {
    const actionCodeSettings = {
      url: window.location.toString(),
    };
    sendEmailVerification(user, actionCodeSettings);
    setVerifyTimestamp(Date.now());
  };

  useEffect(() => {
    setPending(true);
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (...params) => {
        setPending(false);
        setUser(...params);
      },
      setError,
    );
    return () => unsubscribe();
  }, []);
  return (
    <AuthContext.Provider
      value={{ user, error, pending, verifyEmail, verifyTimestamp }}
      {...props}
    />
  );
};
const currencies = [
  {
    name: "USD",
    symbol: "$",
  },
  {
    name: "GBP",
    symbol: "£",
  },
];

export const DBContext = createContext();
export const DBContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [pending, setPending] = useState(true);
  const [currency, setCurrency] = useState("USD");
  // Setup functions
  const functions = {
    generatePayment: httpsCallable(fbFunctions, "generatePayment"),
    applyDiscount: httpsCallable(fbFunctions, "applyDiscount"),
    eSIMMetadataUpdate: httpsCallable(fbFunctions, "eSIMMetadataUpdate"),
  };

  useEffect(() => {
    const getUserLocale = () => {
      // Get user's language preference
      const userLanguage = navigator.language || navigator.userLanguage;

      // Check if the language is set to en-GB (English - United Kingdom)
      const isUKLanguage = userLanguage?.toLowerCase() === "en-gb";

      return isUKLanguage;
    };

    // Set currency based on user's language preference
    setCurrency(getUserLocale() ? "GBP" : "USD");
  }, [setCurrency]);

  const getSymbolForCurrency = (currency) => {
    const result = currencies.find((curr) => curr.name === currency);
    if (!result) {
      throw new Error(`Unknown currency ${currency}`);
    }

    return result.symbol;
  };

  const getCheckoutByID = async (id, userID) => {
    const docRef = doc(db, `customers/${userID}/checkout_sessions`, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Check if session is still usable
      return docSnap.data();
    } else {
      throw new Error("Checkout Session not found");
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const productsSnapshot = await getDocs(
        query(collection(db, "customerPlans"), where("active", "==", true)),
      );
      const productsData = productsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(productsData);

      const countryMap = {};
      const countryTranslationMap = {};
      const regionMap = {};
      const countryTranslator = new CountryTranslator(["en"]);

      for (const product of productsData) {
        if (product.type === "country") {
          const countryCode = product.country;
          if (!countryMap[countryCode]) {
            let countryDetails = countryTranslationMap[countryCode];
            if (!countryDetails) {
              const countryName =
                await countryTranslator.getNameFromCountryCode(
                  countryCode,
                  "en",
                );
              const alternativeCountryName =
                await countryTranslator.getAlternativeNameFromCountryCode(
                  countryCode,
                  "en",
                );
              countryDetails = {
                name: countryName,
                type: "country",
                imageURL: `/flags/${countryCode}.svg`,
                backgroundImageURL: `/countries/${countryCode}.png`,
                url: `/store/country/${countryCode}`,
                code: countryCode,
                otherNames: [countryCode],
              };
              if (alternativeCountryName)
                countryDetails.otherNames = countryDetails.otherNames.concat(
                  alternativeCountryName,
                );
            }
            countryMap[countryCode] = {
              products: [],
              ...countryDetails,
            };
          }
          countryMap[countryCode].products.push(product);
        } else if (product.type === "region") {
          const regionCode = product.region;
          if (!regionMap[regionCode]) {
            regionMap[regionCode] = {
              products: [],
              type: "region",
              name: product.region,
              imageURL: `/regions/${product.region}.svg`,
              backgroundImageURL: `/regions/${product.region}.svg`,
              url: `/store/region/${product.region}`,
              code: regionCode,
              countries: {},
            };

            for (const country of product.countries) {
              let countryDetails = countryTranslationMap[country.country];
              if (!countryDetails) {
                const countryName =
                  await countryTranslator.getNameFromCountryCode(
                    country.country,
                    "en",
                  );
                countryDetails = {
                  name: countryName,
                  imageURL: `/flags/${country.country}.svg`,
                  backgroundImageURL: `/countries/${country.country}.png`,
                  url: `/store/country/${country.country}`,
                  code: country.country,
                };
              }

              regionMap[regionCode].countries[country.country] = countryDetails;
            }
          }
          regionMap[regionCode].products.push(product);
        }
      }
      const countryList = Object.values(countryMap);
      const regionList = Object.values(regionMap);

      // Compute FROM price for currency
      countryList.forEach((country) => {
        const fromPrice = country.products
          .map((product) =>
            product.prices.find((price) => price.currency === currency),
          )
          .map((price) => price.unit_amount / 100)
          .sort((a, b) => a - b)[0];
        country.fromPrice = fromPrice;
        country.fromPriceCurrency = currencies.find(
          (curr) => curr.name === currency,
        );
      });

      regionList.forEach((region) => {
        const fromPrice = region.products
          .map((product) =>
            product.prices.find((price) => price.currency === currency),
          )
          .map((price) => price.unit_amount / 100)
          .sort((a, b) => a - b)[0];
        region.fromPrice = fromPrice;
        region.fromPriceCurrency = currencies.find(
          (curr) => curr.name === currency,
        );
      });

      const sortedCountryList = countryList.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );

      const sortedRegionList = regionList.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );

      setRegions(sortedRegionList);
      setCountries(sortedCountryList);
      setPending(false);
    };
    fetchProducts();
  }, [currency]);
  const getPrefs = async (uid) =>
    getDoc(doc(db, `customers/${uid}/preferences`, "v1"));
  const setPrefs = async (uid, data) =>
    setDoc(doc(db, `customers/${uid}/preferences`, "v1"), data);

  return (
    <DBContext.Provider
      value={{
        products,
        countries,
        regions,
        pending,
        functions,
        currency,
        getCheckoutByID,
        setCurrency,
        getSymbolForCurrency,
        currencies,
        getPrefs,
        setPrefs,
      }}
      {...props}
    />
  );
};

export const AnalyticsContext = createContext();
export const AnalyticsContextProvider = (props) => {
  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
      }}
      {...props}
    />
  );
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return {
    ...auth,
    isAuthenticated: !!auth.user,
  };
};

export const useDBState = () => {
  const db = useContext(DBContext);
  return {
    ...db,
  };
};

export const useAnalyticsState = () => {
  const analytics = useContext(AnalyticsContext);
  return {
    ...analytics,
    logEvent: (name, meta) => logEvent(analytics.analytics, name, meta),
  };
};
